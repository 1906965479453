<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      size="lg"
      :title="titleModal"
      color="dark"
      :closeOnBackdrop="false"
      :show.sync="ModalActividadClientes"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>
      <CRow>
        <CCol sm="12">
          <CRow>
            <CCol sm="3" class="text-right mt-1 pr-1">
              <label><b class="text-danger"> * </b> {{ $t('label.activity') }} </label>
            </CCol>
            <CCol sm="7">
                <div class="form-group form-row">
                  <div class="input-group col-sm-12 col-lg-12 input-group">
                    <v-select 
                      :options="activityOptions"
                      label="TpClientName"
                      :placeholder="$t('label.select')"      
                      v-model.trim="$v.TpClientId.$model"
                      :value.sync="TpClientId"
                      :is-valid="hasError($v.TpClientId)"                          
                      v-uppercase        
                      :invalid-feedback="errorMessage($v.TpClientId)"                 
                      :class="computedSelectClient 
                        ? 'select-client--correct' 
                        : (verifyClient ? 'select-client--error' : '') 
                      "
                      style="width: 92% !important;"
                      :filter="fuseSearchActividades"
                    /> 
                    <div class="input-group-append">
                      <CButton color="add" size="sm" class="mb-1" 
                        v-c-tooltip="{
                          content: $t('label.nueva')+''+$t('label.activity'),
                          placement: 'top-end'
                          }"
                        @click="ActCollapse = !ActCollapse">
                        <CIcon name="cil-playlist-add"/>
                      </CButton>
                    </div>
                  </div>
                  <div class="col-sm-11 col-lg-12 text-invalid-feedback" 
                    v-if="$v.TpClientId.$error && !$v.TpClientId.required && verifyClient"
                  >
                    {{$t('label.required') }} 
                  </div>
                </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" class="mb-3">
          <CRow>
            <CCol sm="3" class="text-right pr-1">
              <label class="mt-2"><b class="text-danger"> * </b> {{ $t('label.client') }} </label>
            </CCol>
            <CCol sm="7"><!-- v-model="ClientId" -->
              <multiselect 
                :options="clientesOptions"
                :multiple="true" 
                :placeholder="$t('label.select')"
                 label="name" track-by="name" class="my-1" 
                :taggable="true"
                @tag="addTag"
                v-model.trim="$v.ClientId.$model"
                :is-valid="hasError($v.ClientId)"               
                :class="isClientValid(cliVal)"
                :invalid-feedback="errorMessage($v.ClientId)"   
                style="min-height: 33px;"
                >
              </multiselect>
            </CCol>
            <CCol sm="3">
            </CCol>
            <CCol sm="7">
              <div class="col-sm-12 p-0">
                <div class='text-invalid-feedback'>{{ cambiaTextoValidate(ClientId) }}</div>
              </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" class="mb-3" v-if="this.FgActApplyCompanyBranch == true">
          <CRow>
            <CCol sm="3" class="text-right pr-1">
              <label><b class="text-danger"> * </b> {{$t('label.branch')}}</label>
            </CCol>
            <CCol sm="7">
              <v-select 
                :options="sucursalOptions"
                label="BranchName"
                :placeholder="$t('label.select')"
                v-model.trim="sucursal"
                :value.sync="sucursal"
                :class="computedSelectBranch 
                        ? 'select-client--correct' 
                        : (verifyClient ? 'select-client--error' : '') 
                      "
                style="width: 100%;"     
              />
              <div class="col-sm-11 col-lg-12 pl-0 text-invalid-feedback" 
                  v-if="$v.sucursal.$error && !$v.sucursal.required && verifyClient"
                >
                {{$t('label.required') }} 
              </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" class="mt-3">
          <ActividadCollapse :collapse="ActCollapse" @child-refresh="actualizaActividad" />
        </CCol>
        <CCol sm="12" v-if="!ActCollapse">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="items"
            :fields="fields"
            :loading="LoadingTabla"
            column-filter
            :noItemsView="tableText.noItemsViewText"
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :items-per-page="5"
            hover
            small
            sorter
            pagination
             
          >
          <template #loading>
          <loading/>
          </template>
            <template #Status="{item}">
              <td class="center-cell">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
          <CButton color="add" :disabled="isSubmit" @click="guardar()" >
            <CIcon name="checkAlt"/>&nbsp; {{$t('button.accept')}}
          </CButton>           
          <CButton outline color="wipe" :disabled="isSubmit" @click="closeModal">
            <CIcon name="x"/>&nbsp; <span class="ml-1">{{$t('button.cancel')}}</span>
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import ActividadClienteValidations from '@/_validations/cliente/actividadClienteValidations';
import { DateFormater } from '@/_helpers/funciones';
import UpperCase  from '@/_validations/uppercase-directive';
import ActividadCollapse  from './actividad-collapse';
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';
import Fuse from "fuse.js";
import { errorMessage } from '@/_helpers/funciones';

function data () {
  return {
    //MODELO
    ClientTpId: '',
    ClientId: [],
    TpClientId: [],
    UserId: '',
    //VARIABLES
    items: [],
    clientes: [],
    actividades: [],
    sucursales: [],
    sucursal: '',
    Loading: false,
    LoadingTabla: false,
    titleModal: null,
    ModalActividadClientes: false,
    ActCollapse: false,
    innerCollapse: false,
    iconoCollapse: 'cil-plus',
    clientesOptions:[],
    vActividad: false,
    vCliente: false,
    cliVal: false,
    isSubmit: false, 
    verifyClient: false,
    FgActApplyCompanyBranch: false,
  }
}

//FUNCIONES DE METHOD
async function getSucursales() {
  let id = this.user.CompanyId;
  await this.$http.ejecutar('GET', 'Branch-list-by-company', {CompanyId: id, Filter: 'ACTIVO'})
  .then(response => {
    this.sucursales = response.data.data;
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}




function closeModal() {
  this.refreshComponent();
  this.$emit('child-refresh', true); 
  this.ModalActividadClientes = false;
}
function actualizaActividad(valor) {
  this.listarActividades();
}

function refreshComponent() {
  this.ActCollapse= false;
  this.ClientId = [];
  this.TpClientId = '';
  this.cliVal = false;
  this.verifyClient= false;
  this.sucursal = '';
  this.sucursales = [];
  this.FgActApplyCompanyBranch = false;
  this.$nextTick(() => { this.$v.$reset() })
}

//clientes por actividad
async function listarClientesByActividad(activ) {
  this.items = [];
  this.LoadingTabla= true;
  let _lang = this.$i18n.locale;
  let listado = Array;
  await this.$http.get("Client-list-by-activity", { TpClientId: activ })
  .then(response => {
    listado = [...response.data.data];
    this.items = listado.map(listado => Object.assign({}, this.items, {
      Nro: listado.Nro,
      Cliente: listado.ClientName,
      TpClientName: _lang=='en' ? listado.TpClientNameEn : listado.TpClientNameEs,
      BranchName: listado.BranchName ? listado.BranchName : 'N/A',
      Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
      Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
      Status: listado.Status,
      ClientTpId: listado.ClientTpId,
      ClientId: listado.ClientId,
      ClientRif: listado.ClientRif,
      FgActClientTp: listado.FgActClientTp,
      _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.LoadingTabla = false;
  });
}

function guardar() {
  try {
    //this.Loading = true;
    this.isSubmit = true;
    this.verifyClient = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.Loading = false;
      this.isSubmit = false;
      this.cliVal = true;
      throw this.$t('label.errorsPleaseCheck');     
    }
  let res = [];
  let ClientTpJson = [];
  
  ClientTpJson = this.ClientId.map(ob => Object.assign({}, ClientTpJson, {
    TpClientId: this.TpClientId.TpClientId,
    ClientId: ob.value,
    UserId: this.user.UserId,
    CompanyBranchId: this.sucursal.CompanyBranchId ? this.sucursal.CompanyBranchId : '',
  }));
  
  this.$http.post("ClientActivity-insert", ClientTpJson, { root: 'ClientTpJson' })
  .then(response => {
    res = [...response.data.data];
    //this.listarClientesByActividad(this.TpClientId.TpClientId);
    let actividadId='';
    
    actividadId= this.TpClientId.TpClientId;
    this.$emit("getDataId", actividadId);
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: res[0].Response,
      type: "success"
    });
    
    this.Loading= false; 
    this.isSubmit = false;    
    this.closeModal();
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.Loading= false;
    this.isSubmit = false;
  });
  } catch (e) {
    this.notifyError({ text: e });
    }
}

//filtro de las actividades en el select
function fuseSearchActividades(options, search) {
  const fuse = new Fuse(options, {
    keys: ["TpClientName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list; 
}

function listBranch() {
  this.listarActividades();
}
//lista de actividades del select

function listarActividades () {
  this.Loading = true;
  this.actividades = [];
  this.vActividad=true;
  let listado = [];
  let activityList = [];
  let activity; 
  let _lang = this.$i18n.locale;
  this.$http.get("TpClient-list", { Filter: 'ACTIVO' })
  .then(async response => {
    listado = [...response.data.data];
   
    activityList = listado.map(ob => Object.assign({}, activityList, {
      TpClientId: ob.TpClientId,
      TpClientName: _lang=='en' ? ob.TpClientNameEn : ob.TpClientNameEs,
      FgActApplyCompanyBranch: ob.FgActApplyCompanyBranch
    }));
    this.actividades = activityList;

    //ALMACENA LOS QUE CUMPLAN CON EL FgActApplyCompanyBranch = TRUE
    activity = listado.find(item =>  item.TpClientId == this.TpClientId.TpClientId )

    if (activity?.FgActApplyCompanyBranch === true) {
      this.FgActApplyCompanyBranch = true;
      await this.getSucursales();
    }else{
      this.FgActApplyCompanyBranch = false;
    }
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).
  then(() => {
    this.Loading = false;
    this.vActividad=false;
  });
}

//lista de clientes del multiselect
function listarClientes () {
  this.clientesOptions = [];
  this.vCliente=true;
  let listado = [];

  this.$http.get("Client-list", { Filter: 'ACTIVO' })
  .then(response => {
    listado = [...response.data.data];
    this.clientesOptions = listado.map(ob => Object.assign({}, this.clientesOptions, {
      value: ob.ClientId,
      name: ob.ClientName
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).
  then(() => {
    this.vCliente=false;
  });
}

function isTpClientValid(cliVal) {
  if(this.cliVal === true){ 
    return (this.TpClientId == null || this.TpClientId == "") ? 'select-client--error' : 'select-client--correct';
  }
}
function isClientValid(cliVal) {
  if(this.cliVal === true){ 
    return (this.ClientId == null || this.ClientId == "") ? 'select-client--error' : 'select-client--correct';
  }
}

function cambiaTextoValidate(cliVal) {
  if(this.cliVal === true){ 
    if(this.ClientId == null || this.ClientId == ""){ 
      return this.$t("label.required");
    }
  }
}

function cambiaTextoValidateTpClient(cliVal) {
  if(this.cliVal === true){ 
    if(this.TpClientId == null || this.TpClientId == ""){ 
      return this.$t("label.required");
    }
  }
}

function addTag (newTag) {
  const tag = {
    name: newTag,
    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
  }
  this.clientesOptions.push(tag)
  this.ClientId.push(tag)
}

function computedSelectBranch() {
  if (this.sucursal === null||this.sucursal === '') {
    return false;
  }else{
    return true;
  }
}

//COMPUTED
function cambiaIcono() {
  return this.iconoCollapse = (this.ActCollapse) ? 'minus' : 'cil-plus';
}

function activityOptions() {
  let _lang = this.$i18n.locale;
  return this.actividades.map((actividad) => Object.assign({}, actividad, {
    label: _lang =='en' ? actividad.TpClientNameEn : actividad.TpClientNameEs,
    value: actividad.TpClientId
  }));
}

function sucursalOptions() {
  return this.sucursales.map((sucursal) => Object.assign({}, sucursal, {
    value: sucursal.CompanyBranchId,
    label: sucursal.BranchName
  }));
}

function fields(){ 
  return[
    { 
        key: 'Nro', 
        label: '#',
        filter: false,_style: 'width:1%; text-align:center;',
        _classes:'text-uppercase text-center center-cell'
    },
    { key: 'Cliente', label: this.$t('label.client'), _style:'width:40%', _classes:'text-uppercase text-center center-cell' },
    { key: 'ClientRif', label: this.$t('label.rifNumber'), _style: 'width:12%', _classes: 'text-uppercase text-center center-cell'},
    { key: 'TpClientName',  label: this.$t('label.activity'), _classes: 'text-uppercase text-center center-cell' },
    { key: 'BranchName',  label: this.$t('label.branch'), _classes: 'text-uppercase text-center center-cell' },
    { key: 'Usuario', label: this.$t('label.user'), _classes:'text-uppercase text-center center-cell'},
    { key: 'Fecha', label: this.$t('label.date'), _style: 'text-align:center;', _classes:'text-center center-cell'},
    { key: 'Status', label: this.$t('label.status'),_style: ' text-align:center;', _classes:'text-center center-cell'},
  ]
}

function computedSelectClient() {
  if (this.TpClientId === '' || this.TpClientId === null) {
    return false;
  } else {
    return true;
  }
}


export default {
  name: 'actividad-clientes-modal',
  mixins: [General, ModalMixin],
  components: {
    ActividadCollapse
  },
  data,
  directives: UpperCase,
  validations(){ return ActividadClienteValidations(this.FgActApplyCompanyBranch) },
  props: {
    modal: null
  },
  methods: {
    refreshComponent,
    guardar,
    fuseSearchActividades,
    listBranch,
    listarActividades,
    listarClientes,
    actualizaActividad,
    addTag,
    listarClientesByActividad,
    errorMessage,
    isTpClientValid,
    isClientValid,
    cambiaTextoValidate,
    cambiaTextoValidateTpClient,
    closeModal,
    getSucursales
  },
  watch: {
    modal: function (val) {
      if (this.modal) {
        if (val) {
          this.listarActividades();
        }
        let listado = [];
        let _lang = this.$i18n.locale;
        this.refreshComponent();
        this.ModalActividadClientes = true;
        this.titleModal = this.$t('label.clientsByActivity');
       
        listado = this.modal.Clientes;
        this.clientesOptions = listado.map(ob => Object.assign({}, this.clientesOptions, {
          value: ob.ClientId,
          name: ob.ClientName
        }));

        listado = [];
        listado = this.modal.Actividades.map(ob => Object.assign({}, listado, {
          TpClientId: ob.TpClientId,
          TpClientName: _lang == 'en' ? ob.TpClientNameEn : ob.TpClientNameEs
        }));
        this.actividades = listado;
        
        if (this.modal.Actividad !== '') {
          let title = _lang == 'en' ? this.modal.Actividad.TpClientNameEn : this.modal.Actividad.TpClientNameEs;
          this.TpClientId = {
            TpClientId: this.modal.Actividad.TpClientId,
            TpClientName: _lang == 'en' ? this.modal.Actividad.TpClientNameEn : this.modal.Actividad.TpClientNameEs,    
          }
          this.titleModal = this.$t('label.clientsByActivity')+': '+title;
        } 

        this.$emit('cerrarModal');
      }
    },
    TpClientId: async function(newClie) {
      if (newClie) {
        if (newClie.TpClientId!==undefined) {
          this.Loading = true;
          await this.listarClientesByActividad(newClie.TpClientId);
          await this.listBranch();
        }       
      }else {
        this.FgActApplyCompanyBranch = false;
      } 
    }
  },
  computed: {
    fields,
    cambiaIcono,    
    activityOptions,
    computedSelectClient,
    computedSelectBranch,
    sucursalOptions,
    ...mapState({
        user: state => state.auth.user,
    })
  }
}
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>